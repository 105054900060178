import React from 'react';
import './RouteModal.css';

const RouteModal = ({ route, onClose }) => {
  return (
    <div className="route-modal">
      <div className="route-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{route.name}</h2>
        <p>Difficulty: {route.difficulty}</p>
        <p>Comment: {route.comment}</p>
        <div className="image-wrapper">
          <img 
            src={`${process.env.PUBLIC_URL}/${encodeURIComponent(route.image)}`} 
            alt={route.name} 
            className="selected-image" 
            id="route-image"
          />
          {route.holds.map((hold, index) => (
            <div
              key={index}
              className={`hold ${hold.shape} ${hold.fill} ${hold.size} ${hold.type}`}
              style={{
                top: `${hold.y}%`,
                left: `${hold.x}%`,
                backgroundColor: hold.fill === 'filled' ? hold.color : 'transparent',
                borderColor: hold.color,
              }}
            >
              {hold.type === 'start' && <span className="hold-label">S</span>}
              {hold.type === 'top' && <span className="hold-label">T</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RouteModal;
